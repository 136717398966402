<template>
  <div class="col">
    <el-card>
      <template #header>
        <div class="main_header_wrapper">
          <div class="part_one">
            <i class="fa fa-database data_icon"></i>
            <p class="text_wrapping">
              {{ careerStrategies.strategy }}
            </p>
          </div>
        </div>
      </template>

      <el-tree :data="careerStrategies.children" default-expand-all>
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div class="lang_text">{{ data.title }}</div>

          <!--//? start main wrapper for strategy  -->
          <div class="main_wrapping_for_strategy">
            <!-- //? start part one  -->
            <div class="part_one_text">
              <!-- <i class="fa fa-database text_icon_2"></i> -->
              <p class="text_words_2">{{ data.strategy }}</p>
            </div>
          </div>
        </div>
      </el-tree>
    </el-card>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("user/showStrategy", {
        id: this.$route.params.id
      });
    }
  },
  computed: {
    careerStrategies() {
      return this.$store.getters["user/getShowCareerStrategies"];
    },
    careerStrategiesLanguages() {
      return this.$store.getters["user/getShowCareerStrategiesLanguages"];
    }
  },
  components: {
    ServerTable
  }
};
</script>

<style lang="scss" scoped>
.main_header_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .part_one {
    display: flex;
    gap: 10px;

    .data_icon {
      color: #9d9d9d;
    }
    .text_wrapping {
      color: #777;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.7;
    }
  }
}

::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-tree-node {
  outline: 0;
}
::v-deep .el-tree-node__content {
  height: auto !important;
  padding: 10px !important;
  display: block;
}
::v-deep .el-tree-node__children {
  height: auto !important;
}
::v-deep .el-tree-node__expand-icon {
  display: none;
}

.main_wrapping_for_strategy {
  // background: #eee;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .part_one_text {
    display: flex;
    gap: 10px;
    @media (min-width: 320px) and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .text_icon_2 {
      color: #9d9d9d;
    }
    .text_words_2 {
      color: #777;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.7;
    }
  }
}
</style>
